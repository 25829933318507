<template>
  <TopBar />
  <NavBar />
  <div class="container mx-auto flex flex-col lg:flex-row justify-between text-white text-xs px-5 lg:px-3 py-7">
    <img style="height: 380px;"
         class="w-full object-cover"
         src="/images/terms.png">
  </div>
  <div class="container mx-auto rules-content">
    <h1 class="rules-header">View Rules</h1>
    <ul class="rules-list">
      <li><span>Enter your legal name, phone number & email</span></li>
      <li><span>Click on the link sent to your phone & watch the video in its entirety</span></li>
      <li><span>Enter the code given at the end of the video to complete your registration</span></li>
      <li><span>Increase your chance of winning by sharing the video (get an additional raffle ticket for free)</span>
      </li>
      <li><span>Upon completion of registration free raffle ticket will be sent your email</span></li>
    </ul>

    <router-link to="/raffle">
      <button
              class="border-2 px-6 h-full uppercase flex flex-row items-center justify-center relative font-medium border-blue-dark text-blue-dark mt-4 lg:mt-0 lg:py-0 py-3 register-btn">REGISTER
        NOW
      </button>
    </router-link>
  </div>


  <Footer />
</template>

<script>
  import Footer from "../components/Footer.vue";
import NavBar from "../components/NavBar.vue";
import TopBar from "../components/TopBar.vue";

  export default {
    name: "Home",
    components: {
      TopBar,
      NavBar,
      Footer,
    },
    data() {
      return {
        coreValues: {
          set1: [{
              icon: "/images/core-values/icon1.png",
              title: "Fairness for all",
            },
            {
              icon: "/images/core-values/icon2.png",
              title: "Obssesed about Customer Success",
            },
            {
              icon: "/images/core-values/icon3.png",
              title: "Striving for Win-Win",
            },
          ],
          set2: [{
              icon: "/images/core-values/icon4.png",
              title: "Finding Creative Solutions",
            },
            {
              icon: "/images/core-values/icon5.png",
              title: "Sustainable",
            },
            {
              icon: "/images/core-values/icon6.png",
              title: "Perfect Assesment",
            },
          ],
        },
      };
    },
  };
</script>

<style>
  .rules-content {}

  .rules-header {
    font-size: 18px;
    font-weight: 600;
    padding: 0px 12px;
  }

  .rules-list {
    list-style-type: disc;
    line-height: 30px;
    padding: 16px 28px;
    font-size: 14px;
  }

  .rules-list li span {
    color: #666666;
  }

  .register-btn {
    background-color: white;
    font-size: 12px;
    padding: 8px 40px;
    margin-bottom: 40px;
    margin-left: 14px;
  }
</style>